import { wFetch } from "./wrappedFetch.js"
// const axios = require("axios")
import { DEFAULT_PAGE_SIZE, ORDER_EXPORT_TYPE, BILL_EXPORT_TYPE } from "@/constants"

export const queryVariants = () => wFetch("/variant/list")
export const homeStatistics =() => wFetch("/dashboard/index")
export const hotItems =() => wFetch("/dashboard/hotItems")
export const hotStores =() => wFetch("/dashboard/hotStores")
export const weekTrade =() => wFetch("/dashboard/weekTrade")

export const queryCategories = variants =>
  wFetch("/cate/query", { variants: variants != null ? variants.join(",") : "" })
export const createCategory = cate => wFetch("/cate/create", cate, "POST")
export const updateCategory = cate => wFetch("/cate/update", cate, "POST")
export const deleteCategory = (alias, moveToAlias) => wFetch("/cate/delete", { alias, moveToAlias }, "DELETE")
export const queryCategoryId = alias => wFetch("/cate/id", { alias })
export const queryCategoryIcon = alias => wFetch("/cate/icon", { alias })

export const queryMedia = page => wFetch("/media/query", { page, pageSize: DEFAULT_PAGE_SIZE })
export const deleteMedia = id => wFetch("/media/delete", { id }, "DELETE")
export const queryMediaSize = media => wFetch("/media/size", { path: media })
export const queryMediaSizes = medias => wFetch("/media/size", { path: medias.join(",") })
export const queryMediaUrls = (medias, suffix = "tiny") => wFetch("/media/url", { path: medias.join(","), suffix })

export const queryItem = (userAlias, page, keyword, startTime, endTime, status, type, variant = "", recommendType = null) =>
  wFetch("/item/query", {
    userAlias,
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword,
    startTime,
    endTime,
    status,
    type,
    variant,
    recommendType,
  })
export const changeItemStatus = (alias, status) => wFetch("/item/changeItemStatus", { alias, status }, "POST")
export const deleteItem = alias => wFetch("/item/delete", { alias }, "DELETE")
export const getAvailableTerms = () => wFetch("/item/terms")
export const publishItem = item => wFetch("/item/publish", item, "POST")
export const getItemDetail = alias => wFetch("/item/getDetail", { alias })
export const queryItemIds = aliases => wFetch("/item/ids", { aliases: aliases.join(",") })
export const queryItemByIds = ids => wFetch("/item/listByIds", { ids: ids.join(",") })

export const queryOrder = (page, orderNo, promoCode, startTime, endTime, orderBy, status) =>
  wFetch("/order/list", {
    page,
    orderNo,
    promoCode,
    startTime,
    endTime,
    orderBy,
    status,
    pageSize: DEFAULT_PAGE_SIZE,
  })
export const queryUserOrder = (page, orderNo, type, userId, startTime, endTime, orderBy, status) =>
  wFetch("/order/userOrderList", {
    page,
    orderNo,
    type,
    userId,
    startTime,
    endTime,
    orderBy,
    status,
    pageSize: DEFAULT_PAGE_SIZE,
  })
export const queryUserBill = userId => wFetch("/bill/userBill", { userId })
export const queryUserInvite = (userId, page, startTime, endTime) =>
  wFetch("/user/userInviteList", { userId, page, startTime, endTime, pageSize: DEFAULT_PAGE_SIZE })
export const queryUserCredit = (userId, page, startTime, endTime) =>
  wFetch("/user/userCreditList", { userId, page, startTime, endTime, pageSize: DEFAULT_PAGE_SIZE })
export const openUserShop = userId => wFetch("/user/openUserShop", { userId })
export const closeOrder = orderNo => wFetch("/order/closeOrder", { orderNo }, "POST")
export const getOrderDetail = orderNo => wFetch("/order/getDetail", { orderNo })
export const queryReceipts = (page, orderNo, startTime, endTime, paymentChannel, status) =>
  wFetch("/receipt/list", { page, orderNo, startTime, endTime, paymentChannel, status, pageSize: DEFAULT_PAGE_SIZE })

export const queryWithdraw = (page, startTime, endTime, status) =>
  wFetch("/bill/withdrawList", { page, startTime, endTime, status, pageSize: DEFAULT_PAGE_SIZE })

export const getPackagePresets = () => wFetch("/shipment/packagePresets")

export const queryAdmin = (page, name, roleAlias, startTime, endTime, status) =>
  wFetch("/admin/query", {
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    name,
    roleAlias,
    startTime,
    endTime,
    status,
  })
export const createAdmin = admin => wFetch("/admin/create", admin, "POST")
export const updateAdmin = admin => wFetch("/admin/update", admin, "POST")
export const deleteAdmin = alias => wFetch("/admin/delete", { alias }, "DELETE")
export const updateAdminPassword = admin => wFetch("/admin/updateAdminPassword", admin, "POST")
export const queryViolation = (page, startTime, endTime, status) =>
  wFetch("/violation/query", {
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    startTime,
    endTime,
    status,
  })
export const changeViolationStatus = (id, status) => wFetch("/violation/changeStatus", { id, status }, "POST")

export const queryAllRoles = () => wFetch("/role/all")
export const queryAllRolesWithPermissions = () => wFetch("/role/allWithPermissions")
export const queryPermissions = () => wFetch("/role/permissions")
export const queryAllPermissions = () => wFetch("/role/allPermissions")
export const createRole = role => wFetch("/role/create", role, "POST")
export const updateRole = role => wFetch("/role/update", role, "POST")
export const deleteRole = roleAlias => wFetch("/role/delete", { roleAlias }, "DELETE")

export const queryPlan = (page, position, name, startScheduleTime, endScheduleTime, startTime, endTime, status) =>
  wFetch("/plan/query", {
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    position,
    name,
    startScheduleTime,
    endScheduleTime,
    startTime,
    endTime,
    status,
  })
export const createPlan = plan => wFetch("/plan/create", plan, "POST")
export const updatePlan = plan => wFetch("/plan/update", plan, "POST")
export const deletePlan = id => wFetch("/plan/delete", { id }, "DELETE")
export const queryItemBlacklist = () => wFetch("/plan/getItemBlacklist")
export const putItemBlacklist = (blacklist) => wFetch("/plan/itemBlacklist", { blacklist }, "POST")
export const queryNotification = (
  page,
  title,
  startScheduleTime,
  endScheduleTime,
  startSentTime,
  endSentTime,
  startTime,
  endTime,
) =>
  wFetch("/notification/query", {
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    title,
    startScheduleTime,
    endScheduleTime,
    startSentTime,
    endSentTime,
    startTime,
    endTime,
  })
export const createNotification = plan => wFetch("/notification/create", plan, "POST")
export const updateNotification = plan => wFetch("/notification/update", plan, "POST")
export const deleteNotification = id => wFetch("/notification/delete", { id }, "DELETE")

// export const queryUser = (page, name, nickname, shopName, startTime, endTime) =>
//   wFetch("/user/list", { page, name, nickname, shopName, startTime, endTime, pageSize: DEFAULT_PAGE_SIZE })
export const queryUser = parmes =>
  wFetch("/user/list",  Object.assign({ pageSize: DEFAULT_PAGE_SIZE }, parmes))
export const queryUserWithoutStore = (page, name, nickname, shopName, startTime, endTime) =>
  wFetch("/user/listWithoutStore", { page, name, nickname, shopName, startTime, endTime, pageSize: DEFAULT_PAGE_SIZE })
export const queryUserByIds = ids => wFetch("/user/listByIds", { ids: ids.join(",") })
export const queryUserSession = userId => wFetch("/user/getUserSession", { userId })
export const deleteSession = token => wFetch("/user/deleteUserSession", { token }, "DELETE")

export const queryCutOff = () => wFetch("/stat/cutOff")
export const querySummaryStat = (unit, startTime, endTime) => wFetch("/stat/summary", { unit, startTime, endTime })
export const queryDailyTransaction = (startTime, endTime) => wFetch("/stat/transaction", { startTime, endTime })
export const queryOperationStat = (startTime, endTime) => wFetch("/stat/operation", { startTime, endTime })
export const queryLocationStat = (startTime, endTime) => wFetch("/stat/location", { startTime, endTime })

export const queryAllSettings = () => wFetch("/app/settings")
export const updateAllSettings = mappedData => wFetch("/app/updateSettings", mappedData, "POST")

export const reIndexItem = () => wFetch("/app/es/reIndex", {}, "POST")
export const reIndexShop = () => wFetch("/app/es/reIndexShop", {}, "POST")
export const reIndexGroupbuy = () => wFetch("/app/es/reIndexGroupbuy", {}, "POST")

export const login = (account, password) => wFetch("/admin/login", { account, password }, "POST")
export const logout = () => wFetch("/admin/logout", {}, "DELETE")
export const exportOrder = (startTime, endTime, status) =>
  wFetch("/export/export", { type: ORDER_EXPORT_TYPE, startTime, endTime, status }, "GET")

export const queryPromo = (page, keyword, userAlias, startTime, endTime, status, type) =>
  wFetch("/promo/query", {
    keyword,
    userAlias,
    startTime,
    endTime,
    status,
    type,
    page,
    pageSize: DEFAULT_PAGE_SIZE,
  })

export const queryPromoItems = (id, page, status) =>
  wFetch("/promo/items", {
    id,
    page,
    pageSize: DEFAULT_PAGE_SIZE,
  })
export const queryPromoCashBackers = (id, page) =>
  wFetch("/promo/users", {
    id,
    page,
    pageSize: DEFAULT_PAGE_SIZE,
  })
export const createPromo = promo => wFetch("/promo/create", promo, "POST")
export const updatePromo = promo => wFetch("/promo/edit", promo, "POST")
export const deletePromo = id => wFetch("/promo/delete", { id }, "DELETE")
export const genPromoCode = () => wFetch("/promo/gencode", {}, "POST")
export const changePromoStatus = (id, status) => wFetch("/promo/changeStatus", { id, status }, "POST")

export const getPromoCodeList = (page, count, status) =>
  wFetch(
    "/promo/queryPromoCodes",
    {
      page,
      count,
      status,
    },
    "GET",
  )

export const createGroupbuy = data => wFetch("/groupbuy/create ", data, "POST")
export const queryGroupbuy = (parmes) => wFetch("/groupbuy/query", parmes)
export const getGroupbuyDetil = (alias) => wFetch("/groupbuy/getDetail", { alias })
export const updateGroupbuy = data => wFetch("/groupbuy/update  ", data, "POST")
export const closeGroupbuy = data => wFetch("/groupbuy/close", data, "POST")
export const updateGroupVirtualCount = data => wFetch("/groupbuy/updateGroupVirtualCount  ", data, "POST")
export const listGroupbuyItem = data => wFetch("/groupbuy/listGroupbuyItem  ", data, "POST")
export const unlistGroupbuyItem = data => wFetch("/groupbuy/unlistGroupbuyItem  ", data, "POST")

export const auditWithdraw = withdrawNo => wFetch("/bill/audit", { withdrawNo }, "GET")
export const cancelWithdraw = withdrawNo => wFetch("/bill/revoke", { withdrawNo }, "GET")

export const queryOrderComment = (orderNo, page) => wFetch("/order/comments", {
  orderNo,
  page,
  pageSize: DEFAULT_PAGE_SIZE,
})

export const queryBusinesstype = () =>
  wFetch("/businessType/query", {})
export const createBusinesstype = cate => wFetch("/businessType/create", cate, "POST")
export const updateBusinesstype = cate => wFetch("/businessType/update", cate, "POST")
export const deleteBusinesstype = (alias, moveToAlias) => wFetch("/businessType/delete", {
  alias,
  moveToAlias,
}, "DELETE")
export const queryBusinessTypeIcon = alias => wFetch("/businessType/icon", { alias })

export const queryStore = store =>
  wFetch("/store/query", store)
export const createStore = store => wFetch("/store/create", store, "POST")
export const updateStore = store => wFetch("/store/update", store, "POST")
export const getDetail = alias => wFetch("/store/getDetail", { alias })
export const disableStore = alias => wFetch("/store/disable", { alias })
export const enableStore = alias => wFetch("/store/enable", { alias })

export const queryArticle = article =>
  wFetch("/article/query", article)
export const createArticle = article => wFetch("/article/create", article, "POST")
export const updateArticle = article => wFetch("/article/edit", article, "POST")
export const getArticleDetail = alias => wFetch("/article/getDetail", { alias })
export const deleteArticle = alias => wFetch("/article/delete", { alias }, "DELETE")
export const showArticle = alias => wFetch("/article/show", { alias })
export const hideArticle = alias => wFetch("/article/hide", { alias })

// 获取活动页列表
export const queryEventList = () => wFetch("/plan/whitelist")
