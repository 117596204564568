export const wFetch = async (url, payload = {}, method = "GET", baseUrl = "/backstage") => {
  url = `${baseUrl}${url}`
  console.log(method, url, payload)
  method = method.toUpperCase()
  const headers = {
    Accept: "application/json",
  }
  const token = sessionStorage.getItem("token")
  if (token) {
    headers["X-Auth"] = token
  }
  let options = { credentials: "same-origin" }
  if (method === "GET" || method === "DELETE") {
    if (Object.keys(payload).length) {
      const searchParams = new URLSearchParams()
      Object.keys(payload).forEach(key => {
        if (payload[key] !== null && payload[key] !== undefined) searchParams.append(key, payload[key])
      })

      url = `${url}?${searchParams.toString()}`
    }
    options = {
      method,
      headers,
      ...options,
    }
  } else if (method === "POST" || method === "PUT") {
    const body = JSON.stringify(payload)
    options = {
      method,
      body,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      ...options,
    }
  }

  const response = await fetch(url, options)
  if (!response.ok) {
    if (response.msg) {
      throw new Error(response.msg, url, options)
    }
    if (response.message) {
      throw new Error(response.message, url, options)
    }
    throw new Error("Network request error", url, options)
  }

  const json = await response.json()
  console.log(`RE: ${url}`, json)
  if (json.code === 255) {
    location.href = "/login"
    throw new Error("用户会话已经过期，重新跳转登录")
  } else if (json.msg !== "ok" || json.code !== 0) {
    throw new Error(json.msg)
  } else {
    return json.data
  }
}
